import { useState, useEffect } from "react"
import { useAuth } from "@/contexts/AuthContext"
import { collection, doc, getDoc, setDoc, getDocs, query, where } from "firebase/firestore"
import { db } from "@/firebase"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card"
import { useToast } from "@/hooks/use-toast"
import { Plus, Link as LinkIcon, Trash2 } from "lucide-react"
import { useSearchConsole } from '@/hooks/useSearchConsole'
import { SearchConsoleService } from '@/lib/services/search-console'
import { useProjects } from '@/contexts/ProjectContext'

interface Project {
  id: string
  name: string
  url: string
  gscConnected: boolean
  createdAt: number
  addedFromGSC?: boolean
}

export default function Projects() {
  const { user } = useAuth()
  const { toast } = useToast()
  const [projects, setProjects] = useState<Project[]>([])
  const [newProject, setNewProject] = useState({ name: "", url: "" })
  const [loading, setLoading] = useState(true)
  const { connectedAccounts } = useSearchConsole()
  const { projects: gscProjects, removeProject } = useProjects()

  useEffect(() => {
    loadProjects()
  }, [user, gscProjects])

  const loadProjects = async () => {
    if (!user?.uid) return
    try {
      // Get projects that were added from GSC
      const gscProjectsData = gscProjects.map(p => ({
        id: p.domain,
        name: p.name,
        url: `https://${p.domain}`,
        gscConnected: true,
        createdAt: new Date(p.createdAt).getTime(),
        addedFromGSC: true
      }))

      // Get manually added projects
      const projectsRef = collection(db, "users", user.uid, "projects")
      const q = query(projectsRef, where("addedFromGSC", "!=", true))
      const snapshot = await getDocs(q)
      const manualProjectsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Project[]

      // Combine both sets of projects
      const allProjects = [...gscProjectsData, ...manualProjectsData]
      setProjects(allProjects.filter(p => !p.deleted))
    } catch (error) {
      console.error("Error loading projects:", error)
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to load projects"
      })
    } finally {
      setLoading(false)
    }
  }

  const handleAddProject = async (name: string, url: string) => {
    if (!user?.uid || !name || !url) return
    try {
      // Check if project already exists
      const exists = projects.some(p => p.url === url || p.name === name)
      if (exists) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "Project already exists"
        })
        return
      }

      const projectData: Omit<Project, "id"> = {
        name,
        url,
        gscConnected: false,
        createdAt: Date.now(),
        addedFromGSC: false
      }
      const projectRef = doc(collection(db, "users", user.uid, "projects"))
      await setDoc(projectRef, projectData)
      setProjects(prev => [...prev, { id: projectRef.id, ...projectData }])
      setNewProject({ name: "", url: "" })
      toast({
        title: "Success",
        description: "Project added successfully"
      })
    } catch (error) {
      console.error("Error adding project:", error)
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to add project"
      })
    }
  }

  const handleDeleteProject = async (projectId: string, addedFromGSC: boolean = false) => {
    if (!user?.uid) return
    try {
      if (addedFromGSC) {
        // Remove from ProjectContext if it's a GSC project
        await removeProject(projectId)
      } else {
        // Remove from Firestore if it's a manual project
        await setDoc(doc(db, "users", user.uid, "projects", projectId), {
          deleted: true,
          deletedAt: Date.now(),
        }, { merge: true })
      }
      setProjects(projects.filter((p) => p.id !== projectId))
      toast({
        title: "Success",
        description: "Project deleted successfully"
      })
    } catch (error) {
      console.error("Error deleting project:", error)
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to delete project"
      })
    }
  }

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <div className="container mx-auto p-6">
      <div className="mb-6 flex items-center justify-between">
        <h1 className="text-2xl font-bold">Projects</h1>
        <Button onClick={() => document.getElementById("addProject")?.focus()}>
          <Plus className="mr-2 h-4 w-4" /> Add Project
        </Button>
      </div>

      <Card className="mb-6">
        <CardHeader>
          <CardTitle>Add New Project</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="grid gap-4 md:grid-cols-2">
            <Input
              id="addProject"
              placeholder="Business Name"
              value={newProject.name}
              onChange={(e) =>
                setNewProject({ ...newProject, name: e.target.value })
              }
            />
            <Input
              placeholder="Website URL"
              value={newProject.url}
              onChange={(e) =>
                setNewProject({ ...newProject, url: e.target.value })
              }
            />
          </div>
          <Button onClick={() => handleAddProject(newProject.name, newProject.url)}>Add Project</Button>
        </CardContent>
      </Card>

      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="border-b">
              <th className="py-3 px-4 text-left">Business Name</th>
              <th className="py-3 px-4 text-left">Website</th>
              <th className="py-3 px-4 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project) => (
              <tr key={project.id} className="border-b">
                <td className="py-3 px-4">{project.name}</td>
                <td className="py-3 px-4">
                  <a href={project.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                    {project.url}
                  </a>
                </td>
                <td className="py-3 px-4 text-center">
                  <Button
                    variant="destructive"
                    size="sm"
                    onClick={() => handleDeleteProject(project.id, project.addedFromGSC)}
                  >
                    <Trash2 className="h-4 w-4 mr-2" />
                    Remove
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
