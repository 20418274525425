import { createContext, useContext, useState, ReactNode } from 'react';

interface Project {
  name: string;
  domain: string;
  type: string;
  createdAt: string;
}

interface ProjectContextType {
  projects: Project[];
  addProject: (project: Project) => Promise<void>;
  removeProject: (domain: string) => Promise<void>;
}

const ProjectContext = createContext<ProjectContextType | undefined>(undefined);

export function ProjectProvider({ children }: { children: ReactNode }) {
  const [projects, setProjects] = useState<Project[]>([]);

  const addProject = async (project: Project) => {
    setProjects(prev => [...prev, project]);
  };

  const removeProject = async (domain: string) => {
    setProjects(prev => prev.filter(p => p.domain !== domain));
  };

  return (
    <ProjectContext.Provider value={{ projects, addProject, removeProject }}>
      {children}
    </ProjectContext.Provider>
  );
}

export function useProjects() {
  const context = useContext(ProjectContext);
  if (context === undefined) {
    throw new Error('useProjects must be used within a ProjectProvider');
  }
  return context;
}
