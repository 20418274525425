import React from 'react';
    import GoogleLogin from '../components/GoogleLogin';

    const Login: React.FC = () => {
      return (
        <div>
          <GoogleLogin />
        </div>
      );
    };

    export default Login;
