import * as React from "react"
import { useNavigate } from "react-router-dom"
import {
  Home,
  Settings,
  User,
  Search,
  LineChart,
  Globe,
  FileText,
  Target,
  Users,
  Wrench,
  LogOut,
} from "lucide-react"
import { auth } from "@/firebase"
import { signOut } from "firebase/auth"
import { useAuth } from "@/contexts/AuthContext"
import { removeTokens } from "@/lib/search-console"
import { useToast } from "@/hooks/use-toast"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from "@/components/ui/sidebar"

const navigation = [
  { name: "Dashboard", href: "/", icon: Home },
  { name: "Content Analysis", href: "/content", icon: FileText },
  { name: "SEO Audit", href: "/audit", icon: Search },
  { name: "Performance", href: "/performance", icon: LineChart },
  { name: "Technical SEO", href: "/technical", icon: Wrench },
  { name: "Projects", href: "/projects", icon: Globe },
  { name: "Settings", href: "/settings", icon: Settings },
]

export function SidebarNav() {
  const [active, setActive] = React.useState("/")
  const [showLogout, setShowLogout] = React.useState(false)
  const { user } = useAuth()
  const navigate = useNavigate()
  const toast = useToast()

  const handleLogout = async () => {
    try {
      // Clear Search Console tokens first
      if (user?.uid) {
        await removeTokens(user.uid);
      }

      // Sign out from Firebase
      await signOut(auth);

      // Clear any local storage
      localStorage.clear();
      sessionStorage.clear();

      // Clear Google's OAuth state
      const googleLogoutUrl = "https://accounts.google.com/logout";
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = googleLogoutUrl;
      document.body.appendChild(iframe);
      
      // Remove the iframe after logout
      setTimeout(() => {
        document.body.removeChild(iframe);
        // Hard redirect to login page to ensure clean state
        window.location.href = "/login";
      }, 1000);

    } catch (error) {
      console.error("Error signing out:", error);
      toast({
        title: "Error signing out",
        description: "Please try again",
        variant: "destructive",
      });
    }
  }

  return (
    <Sidebar className="border-r">
      <SidebarHeader className="border-b px-6 py-3">
        <span className="text-xl font-semibold">SEO Analysis</span>
      </SidebarHeader>
      <SidebarContent>
        <nav className="space-y-1 px-3 py-2">
          {navigation.map((item) => (
            <Button
              key={item.name}
              variant={active === item.href ? "secondary" : "ghost"}
              className={cn(
                "w-full justify-start gap-2 px-3 py-2",
                active === item.href ? "bg-secondary" : "hover:bg-secondary/50"
              )}
              onClick={() => {
                setActive(item.href)
                navigate(item.href)
              }}
            >
              <item.icon className="h-4 w-4" />
              <span className="text-sm font-medium">{item.name}</span>
            </Button>
          ))}
        </nav>
      </SidebarContent>
      <SidebarFooter className="border-t p-2">
        <div className="relative">
          <Button
            variant="ghost"
            className="w-full justify-start gap-2 px-3 py-2"
            onClick={() => setShowLogout(!showLogout)}
          >
            {user?.photoURL ? (
              <img
                src={user.photoURL}
                alt={user?.displayName || "Profile"}
                className="h-6 w-6 rounded-full"
                referrerPolicy="no-referrer"
              />
            ) : (
              <User className="h-4 w-4" />
            )}
            <span className="text-sm font-medium">
              {user?.displayName || user?.email || "Profile"}
            </span>
          </Button>
          {showLogout && (
            <Button
              variant="ghost"
              className="absolute bottom-full left-0 mb-1 w-full justify-start gap-2 px-3 py-2 text-red-500 hover:bg-red-50 hover:text-red-600"
              onClick={handleLogout}
            >
              <LogOut className="h-4 w-4" />
              <span className="text-sm">Logout</span>
            </Button>
          )}
        </div>
      </SidebarFooter>
    </Sidebar>
  )
}
