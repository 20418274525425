import { SearchAnalyticsQuery, SearchAnalyticsResponse, SearchConsoleProperty } from './types';

export async function fetchSearchConsoleProperties(accessToken: string): Promise<SearchConsoleProperty[]> {
  const response = await fetch(
    "https://www.googleapis.com/webmasters/v3/sites",
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch properties: ${response.statusText}`);
  }

  const data = await response.json();
  return data.siteEntry || [];
}

export async function fetchSearchAnalytics(
  accessToken: string,
  siteUrl: string,
  query: SearchAnalyticsQuery
): Promise<SearchAnalyticsResponse> {
  const response = await fetch(
    `https://www.googleapis.com/webmasters/v3/sites/${encodeURIComponent(siteUrl)}/searchAnalytics/query`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(query),
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch analytics: ${response.statusText}`);
  }

  return response.json();
}

export async function validateToken(accessToken: string): Promise<boolean> {
  try {
    await fetchSearchConsoleProperties(accessToken);
    return true;
  } catch (error) {
    return false;
  }
}
