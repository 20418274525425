import { collection, doc, getDocs, query, setDoc, where, deleteDoc, getDoc } from 'firebase/firestore';
import { db } from '@/firebase';
import { fetchSearchAnalytics, fetchSearchConsoleProperties, validateToken } from '../api/search-console/queries';
import { SearchAnalyticsQuery, SearchConsoleToken } from '../api/search-console/types';
import { refreshAccessToken } from '../api/search-console/auth';

interface GSCToken {
  accessToken: string;
  refreshToken: string | null;
  email: string;
  profilePicture: string;
  lastUpdated: string;
}

interface SearchAnalyticsResponse {
  rows?: {
    clicks: number;
    impressions: number;
    ctr: number;
    position: number;
    keys: string[];
  }[];
}

export class SearchConsoleService {
  private userId: string;
  private collectionName = 'gsc_tokens';

  constructor(userId: string) {
    this.userId = userId;
  }

  async storeToken(data: SearchConsoleToken) {
    // Use email as the document ID since it's unique per GSC account
    const tokenCollection = collection(db, "users", this.userId, "search_console_accounts");
    const tokenDoc = doc(tokenCollection, data.email);
    
    await setDoc(tokenDoc, {
      ...data,
      lastUpdated: new Date().toISOString(),
      expiresAt: data.expiresIn ? new Date(Date.now() + data.expiresIn * 1000).toISOString() : null,
      status: 'active'
    });
  }

  async getTokens(): Promise<SearchConsoleToken[]> {
    const tokensQuery = query(
      collection(db, "users", this.userId, "search_console_accounts"),
      where("status", "==", "active")
    );
    const snapshot = await getDocs(tokensQuery);
    
    return snapshot.docs
      .filter(doc => doc.data().status !== 'revoked')
      .map(doc => ({
        ...doc.data(),
        email: doc.id // Use document ID as email
      })) as SearchConsoleToken[];
  }

  async getActiveToken(): Promise<SearchConsoleToken | null> {
    const tokens = await this.getTokens();
    if (tokens.length === 0) return null;

    const token = tokens[0];
    if (!token.expiresAt || new Date(token.expiresAt) > new Date()) {
      return token;
    }

    // Token is expired, try to refresh it
    if (token.refreshToken) {
      try {
        const { accessToken, expiresIn } = await refreshAccessToken(token.refreshToken);
        const updatedToken = {
          ...token,
          accessToken,
          expiresIn,
          lastUpdated: new Date().toISOString(),
          expiresAt: new Date(Date.now() + expiresIn * 1000).toISOString(),
        };
        
        // Store the updated token
        await this.storeToken(updatedToken);
        return updatedToken;
      } catch (error) {
        console.error('Failed to refresh token:', error);
        return null;
      }
    }

    return null;
  }

  async revokeToken(email: string) {
    const tokenCollection = collection(db, "users", this.userId, "search_console_accounts");
    const tokenDoc = doc(tokenCollection, email);
    await deleteDoc(tokenDoc);
  }

  async getProperties() {
    const token = await this.getActiveToken();
    if (!token) throw new Error('No active token found');

    const isValid = await validateToken(token.accessToken);
    if (!isValid) throw new Error('Invalid token');

    return fetchSearchConsoleProperties(token.accessToken);
  }

  async getSearchAnalytics(query: SearchAnalyticsQuery) {
    const token = await this.getActiveToken();
    if (!token) throw new Error('No active token found');

    const isValid = await validateToken(token.accessToken);
    if (!isValid) throw new Error('Invalid token');

    return fetchSearchAnalytics(token.accessToken, query);
  }

  async fetchSearchAnalytics(email: string, startDate: string, endDate: string) {
    const userDoc = doc(db, this.collectionName, this.userId);
    const accountsCollection = collection(userDoc, 'accounts');
    const tokenDoc = await getDoc(doc(accountsCollection, email));
    const token = tokenDoc.data() as GSCToken;

    if (!token?.accessToken) {
      throw new Error('No access token found for this account');
    }

    try {
      // First, get the list of sites
      const sitesResponse = await fetch('https://www.googleapis.com/webmasters/v3/sites', {
        headers: {
          'Authorization': `Bearer ${token.accessToken}`
        }
      });

      if (!sitesResponse.ok) {
        throw new Error('Failed to fetch sites');
      }

      const sites = await sitesResponse.json();
      const siteUrls = sites.siteEntry?.map((site: any) => site.siteUrl) || [];

      // For each site, fetch the search analytics data
      const allResults = await Promise.all(
        siteUrls.map(async (siteUrl: string) => {
          const response = await fetch(
            `https://www.googleapis.com/webmasters/v3/sites/${encodeURIComponent(siteUrl)}/searchAnalytics/query`,
            {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${token.accessToken}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                startDate,
                endDate,
                dimensions: ['query'],
                rowLimit: 10,
              }),
            }
          );

          if (!response.ok) {
            console.error(`Failed to fetch data for site ${siteUrl}`);
            return null;
          }

          const data: SearchAnalyticsResponse = await response.json();
          return {
            siteUrl,
            data: data.rows || [],
          };
        })
      );

      // Filter out any failed requests and combine the results
      return allResults.filter(result => result !== null);
    } catch (error) {
      console.error('Error fetching search analytics:', error);
      throw error;
    }
  }
}
