import { useState, useEffect } from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { Button } from '@/components/ui/button';
import { useToast } from '@/hooks/use-toast';
import { useSearchConsole } from '@/hooks/useSearchConsole';
import { Loader2, RefreshCw, Check } from 'lucide-react';
import { GoogleAuthProvider, signInWithPopup, getAuth } from 'firebase/auth';
import { SearchConsoleService } from '@/lib/services/search-console';
import { useProjects } from '@/contexts/ProjectContext';

interface Project {
  name: string;
  url: string;
  isConnected: boolean;
  isAddedAsProject: boolean;
}

export function GoogleSearchConsoleAuth() {
  const { user } = useAuth();
  const { toast } = useToast();
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { connectedAccounts, loading } = useSearchConsole();
  const [projects, setProjects] = useState<Project[]>([]);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const { addProject, projects: existingProjects } = useProjects();
  const [lastUpdated, setLastUpdated] = useState<string>('');

  useEffect(() => {
    fetchProjects();
  }, [connectedAccounts, existingProjects]);

  const fetchProjects = async () => {
    if (!user || !connectedAccounts.length) {
      setProjects([]);
      return;
    }

    setLoadingProjects(true);
    try {
      const service = new SearchConsoleService(user.uid);
      const account = connectedAccounts[0];

      const response = await fetch('https://www.googleapis.com/webmasters/v3/sites', {
        headers: {
          'Authorization': `Bearer ${account.accessToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch sites');
      }

      const data = await response.json();
      const sites = data.siteEntry?.map((site: any) => {
        const domain = site.siteUrl.replace(/^https?:\/\//, '').replace(/\/$/, '');
        return {
          name: domain,
          url: site.siteUrl,
          isConnected: true,
          isAddedAsProject: existingProjects.some(p => p.domain === domain)
        };
      }) || [];

      setProjects(sites);
      setLastUpdated(new Date().toLocaleString());
    } catch (error) {
      console.error('Error fetching projects:', error);
      toast({
        title: "Error",
        description: "Failed to fetch Search Console sites",
        variant: "destructive",
      });
    } finally {
      setLoadingProjects(false);
    }
  };

  const handleAddAsProject = async (project: Project) => {
    try {
      await addProject({
        name: project.name,
        domain: project.name,
        type: 'website',
        createdAt: new Date().toISOString(),
      });

      setProjects(prev => prev.map(p => 
        p.name === project.name ? { ...p, isAddedAsProject: true } : p
      ));

      toast({
        title: "Success",
        description: `Added ${project.name} as a project`,
      });
    } catch (error) {
      console.error('Error adding project:', error);
      toast({
        title: "Error",
        description: "Failed to add project",
        variant: "destructive",
      });
    }
  };

  const handleRemoveAccount = async (email: string) => {
    if (!user) return;

    try {
      const service = new SearchConsoleService(user.uid);
      await service.revokeToken(email);
      setProjects([]);

      toast({
        title: "Account Removed",
        description: `Removed Search Console access for ${email}`,
      });
    } catch (error: any) {
      toast({
        title: "Error",
        description: "Failed to remove account",
        variant: "destructive",
      });
    }
  };

  const handleGoogleAuth = async () => {
    if (!user) return;

    setIsAuthenticating(true);
    let originalEmail = '';

    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      
      if (currentUser) {
        originalEmail = currentUser.email || '';
      }

      const provider = new GoogleAuthProvider();
      provider.addScope('https://www.googleapis.com/auth/webmasters.readonly');
      provider.setCustomParameters({
        prompt: 'select_account',
        access_type: 'offline'
      });

      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);

      if (!credential?.accessToken) {
        throw new Error('No access token received');
      }

      // Check if this account is already connected
      const existingAccounts = connectedAccounts || [];
      const isDuplicate = existingAccounts.some(
        account => account.email === result.user.email
      );

      if (isDuplicate) {
        toast({
          title: "Account Already Connected",
          description: `The account ${result.user.email} is already connected to Search Console`,
          variant: "destructive",
        });
        return;
      }

      const service = new SearchConsoleService(user.uid);
      const isValid = await service.validateToken(credential.accessToken);
      
      if (!isValid) {
        throw new Error('This Google account does not have Search Console access');
      }

      await service.storeToken({
        accessToken: credential.accessToken,
        refreshToken: null,
        email: result.user.email || '',
        profilePicture: result.user.photoURL || '',
        lastUpdated: new Date().toISOString(),
      });

      if (originalEmail && originalEmail !== result.user.email) {
        await auth.signOut();
        
        const originalProvider = new GoogleAuthProvider();
        originalProvider.setCustomParameters({
          login_hint: originalEmail,
          prompt: 'none'
        });
        
        await signInWithPopup(auth, originalProvider);
      }

      toast({
        title: "Success",
        description: `Connected to Google Search Console with ${result.user.email}`,
      });

      await fetchProjects();

    } catch (error: any) {
      console.error('Error during Search Console authentication:', error);
      
      if (originalEmail) {
        try {
          const auth = getAuth();
          const provider = new GoogleAuthProvider();
          provider.setCustomParameters({
            login_hint: originalEmail,
            prompt: 'none'
          });
          await signInWithPopup(auth, provider);
        } catch (signInError) {
          console.error('Error signing back in:', signInError);
        }
      }

      toast({
        title: "Error",
        description: error.message || "Failed to connect to Google Search Console",
        variant: "destructive",
      });
    } finally {
      setIsAuthenticating(false);
    }
  };

  if (loading || loadingProjects) {
    return <div className="flex items-center gap-2">
      <Loader2 className="h-4 w-4 animate-spin" />
      {loading ? 'Loading accounts...' : 'Loading projects...'}
    </div>;
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-lg font-medium">Google Search Console</h3>
          <p className="text-sm text-gray-500">
            Connect your Google Search Console account to track your websites
          </p>
        </div>
        {connectedAccounts.length === 0 ? (
          <Button
            onClick={handleGoogleAuth}
            disabled={isAuthenticating}
          >
            {isAuthenticating && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            )}
            Connect Account
          </Button>
        ) : (
          <Button
            variant="outline"
            size="sm"
            onClick={fetchProjects}
            disabled={loadingProjects}
          >
            <RefreshCw className={`h-4 w-4 mr-2 ${loadingProjects ? 'animate-spin' : ''}`} />
            Refresh Sites
          </Button>
        )}
      </div>

      {connectedAccounts.length > 0 && (
        <div className="space-y-4">
          <div className="flex items-center justify-between text-sm text-gray-500">
            <span>Connected Accounts</span>
            {lastUpdated && <span>Last updated: {lastUpdated}</span>}
          </div>
          
          {connectedAccounts.map((account) => (
            <div
              key={account.email}
              className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
            >
              <div className="flex items-center gap-3">
                {account.profilePicture && (
                  <img
                    src={account.profilePicture}
                    alt={account.email}
                    className="w-8 h-8 rounded-full"
                  />
                )}
                <div>
                  <p className="font-medium">{account.email}</p>
                  <p className="text-sm text-gray-500">
                    Connected {new Date(account.lastUpdated).toLocaleDateString()}
                  </p>
                </div>
              </div>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => handleRemoveAccount(account.email)}
              >
                Remove
              </Button>
            </div>
          ))}

          {projects.length > 0 && (
            <div className="mt-6">
              <h4 className="font-medium mb-4">Available Sites</h4>
              <div className="space-y-2">
                {projects.map((project) => (
                  <div
                    key={project.url}
                    className="flex items-center justify-between p-3 bg-gray-50 rounded-lg"
                  >
                    <span>{project.name}</span>
                    {project.isAddedAsProject ? (
                      <div className="flex items-center text-green-600">
                        <Check className="h-4 w-4 mr-2" />
                        Added
                      </div>
                    ) : (
                      <Button
                        size="sm"
                        onClick={() => handleAddAsProject(project)}
                      >
                        Add as Project
                      </Button>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
