import { db } from "@/firebase"
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore"

interface TokenData {
  accessToken: string
  refreshToken?: string
  expiresAt?: string
  lastUpdated: string
}

interface SearchAnalyticsData {
  keys: string[]
  clicks: number
  impressions: number
  ctr: number
  position: number
}

interface ProjectMetrics {
  queries: SearchAnalyticsData[]
  lastUpdated: string
  propertyId: string
}

export async function storeTokens(userId: string, tokenData: TokenData) {
  const userTokenRef = doc(db, "users", userId, "tokens", "search-console")
  await setDoc(userTokenRef, {
    ...tokenData,
    lastUpdated: new Date().toISOString(),
  })
}

export async function getStoredTokens(userId: string): Promise<TokenData | null> {
  const userTokenRef = doc(db, "users", userId, "tokens", "search-console")
  const tokenDoc = await getDoc(userTokenRef)
  
  if (!tokenDoc.exists()) {
    return null
  }
  
  return tokenDoc.data() as TokenData
}

export async function removeTokens(userId: string) {
  const userTokenRef = doc(db, "users", userId, "tokens", "search-console")
  await setDoc(userTokenRef, {
    accessToken: null,
    refreshToken: null,
    expiresAt: null,
    lastUpdated: new Date().toISOString(),
  })
}

export async function fetchSearchConsoleProperties(userId: string) {
  try {
    const tokenData = await getStoredTokens(userId);
    if (!tokenData || !tokenData.accessToken) {
      throw new Error('No access token found. User needs to authenticate with Google Search Console.');
    }

    const response = await fetch(
      "https://www.googleapis.com/webmasters/v3/sites",
      {
        headers: {
          Authorization: `Bearer ${tokenData.accessToken}`,
        },
      }
    )

    if (!response.ok) {
      throw new Error("Failed to fetch Search Console properties")
    }

    const data = await response.json()
    return data.siteEntry || []
  } catch (error) {
    console.error("Error fetching Search Console properties:", error)
    throw error
  }
}

export async function fetchSearchAnalytics(userId: string, siteUrl: string, startDate: string, endDate: string) {
  try {
    const tokenData = await getStoredTokens(userId);
    if (!tokenData || !tokenData.accessToken) {
      throw new Error('No access token found. User needs to authenticate with Google Search Console.');
    }

    const response = await fetch(
      `https://www.googleapis.com/webmasters/v3/sites/${encodeURIComponent(siteUrl)}/searchAnalytics/query`,
      {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${tokenData.accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          startDate,
          endDate,
          dimensions: ['query'],
          rowLimit: 100,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch search analytics: ${response.statusText}`);
    }

    const data = await response.json();
    return data.rows || [];
  } catch (error) {
    console.error('Error fetching search analytics:', error);
    throw error;
  }
}

export async function storeProjectMetrics(userId: string, projectId: string, metrics: ProjectMetrics) {
  try {
    console.log('Storing metrics in Firestore:', { userId, projectId, metrics });
    const metricsRef = doc(db, "users", userId, "projects", projectId, "metrics", "search-console");
    await setDoc(metricsRef, {
      ...metrics,
      lastUpdated: new Date().toISOString(),
    });
    console.log('Metrics stored successfully in Firestore');
  } catch (error) {
    console.error('Error storing metrics:', error);
    throw error;
  }
}

export async function getProjectMetrics(userId: string, projectId: string): Promise<ProjectMetrics | null> {
  const metricsRef = doc(db, "users", userId, "projects", projectId, "metrics", "search-console");
  const metricsDoc = await getDoc(metricsRef);
  
  if (!metricsDoc.exists()) {
    return null;
  }
  
  return metricsDoc.data() as ProjectMetrics;
}
