import { useEffect, useState } from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { SearchConsoleService } from '@/lib/services/search-console';
import { SearchAnalyticsResponse, SearchConsoleProperty, SearchConsoleToken } from '@/lib/api/search-console/types';

export function useSearchConsole() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [connectedAccounts, setConnectedAccounts] = useState<SearchConsoleToken[]>([]);
  const [properties, setProperties] = useState<SearchConsoleProperty[]>([]);

  const loadAccounts = async () => {
    if (!user) return;

    try {
      const service = new SearchConsoleService(user.uid);
      const tokens = await service.getTokens();
      setConnectedAccounts(tokens.filter(token => token.status !== 'revoked'));

      // Load properties for each active account
      const allProperties = await Promise.all(
        tokens
          .filter(token => token.status !== 'revoked')
          .map(token => service.getProperties(token.accessToken))
      );

      setProperties(allProperties.flat());
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAccounts();
  }, [user]);

  const removeAccount = async (tokenId: string) => {
    if (!user) return;

    try {
      setLoading(true);
      const service = new SearchConsoleService(user.uid);
      await service.revokeToken(tokenId);
      // Refresh the accounts list
      await loadAccounts();
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  async function getAnalytics(siteUrl: string, startDate: string, endDate: string) {
    if (!user || connectedAccounts.length === 0) {
      throw new Error('No connected Search Console accounts');
    }

    const service = new SearchConsoleService(user.uid);
    const results: SearchAnalyticsResponse[] = [];

    // Try each connected account until we find one with access to the site
    for (const account of connectedAccounts) {
      try {
        const analytics = await service.getAnalytics(account.accessToken, siteUrl, {
          startDate,
          endDate,
          dimensions: ['page']
        });
        results.push(analytics);
      } catch (error) {
        console.log(`Failed to fetch analytics with account ${account.email}`, error);
        continue;
      }
    }

    if (results.length === 0) {
      throw new Error('No accounts have access to this site');
    }

    return results[0]; // Return the first successful result
  }

  return {
    loading,
    error,
    connectedAccounts,
    properties,
    getAnalytics,
    removeAccount
  };
}
