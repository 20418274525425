import { useAuth } from "@/contexts/AuthContext"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { GoogleSearchConsoleAuth } from "@/components/GoogleSearchConsoleAuth"
import { useToast } from "@/hooks/use-toast"

export default function Settings() {
  const { user } = useAuth();
  const { toast } = useToast();

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Settings</h3>
        <p className="text-sm text-muted-foreground">
          Manage your account settings and preferences.
        </p>
      </div>

      <Tabs defaultValue="general">
        <TabsList>
          <TabsTrigger value="general">General</TabsTrigger>
          <TabsTrigger value="search-console">Search Console</TabsTrigger>
        </TabsList>

        <TabsContent value="general">
          <Card>
            <CardHeader>
              <CardTitle>API Keys</CardTitle>
              <CardDescription>
                Configure your API keys for various services.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="space-y-2">
                <Label htmlFor="jinaAiKey">Jina AI Key</Label>
                <Input
                  id="jinaAiKey"
                  placeholder="Enter your Jina AI key"
                  type="password"
                />
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="search-console">
          <GoogleSearchConsoleAuth />
        </TabsContent>
      </Tabs>
    </div>
  );
}
